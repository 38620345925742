import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'pageIndex',
    component: () => import('../views/index.vue')
  },
  // {
  //   path: '/dashboard',
  //   name: 'Index',
  //   component: () => import('../views/dashboard/index.vue')
  // },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
