// import { createApp } from 'vue'
// import App from './App.vue'
// import router from './router'
// import store from './store'
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'
// import '@/assets/iconfont/iconfont.js'
// import SvgIcon from '@/components/SvgIcon.vue'

// app.component('SvgIcon', SvgIcon)
// createApp(App).use(store).use(router).use(ElementPlus).mount('#app')
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import '@/assets/iconfont/iconfont.js'
import SvgIcon from '@/components/SvgIcon.vue'

// 创建 Vue 应用实例
const app = createApp(App)

// 全局注册 SvgIcon 组件
app.component('SvgIcon', SvgIcon)

// 注册 Element Plus 的图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 使用插件
app
    .use(store)
    .use(router)
    .use(ElementPlus)
    .mount('#app')