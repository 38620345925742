<template>
  <div id="app">
    <router-view />
  </div>

</template>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
